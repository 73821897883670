const EVENT_NAME = {
    /**
     * 请求协作
     */
    REQUEST_COLLABORATION:'REQUEST_COLLABORATION',
    /**
     * 协作对方
     */
    COOPERATION_USER:'COOPERATION_USER',
    /**
     * 用户操作行为消息
     */
    USER_OPERATION_INFORMATION:'USER_OPERATION_INFORMATION',
    /**
     *  订阅者截图
     */
    SUBSCRIBER_SCREENSHOT:'SUBSCRIBER_SCREENSHOT',
    /**
     *  绘制url到canvas
     */
    SUBSCRIBER_DRAW:'SUBSCRIBER_DRAW',
    /**
     * 清除划线存储
     */
    EMPTY_LINE_DATA:'EMPTY_LINE_DATA',
    /**
     * 发布者绘制
     */
    PUBLISHER_DRAWING:'PUBLISHER_DRAWING',
    /**
     * 撤销绘制
     */
    UNDO_DRAW:'UNDO_DRAW',
    /**
     * 窗口改变绘制
     */
    CANVAS_RESIZE:'',
    /**
     * 清空绘制
     */
    REMOVE_GRAFFITI:'REMOVE_GRAFFITI',
    /**
     * 按下动作
     */
    MOUSE_DOWN:'MOUSE_DOWN',
    /**
     * 抬起动作
     */
    DRAWING_UP:'DRAWING_UP',
    /**
     * 图片放大
     */
    IMAGE_ENLARGE:'IMAGE_ENLARGE',
    /**
     *  分类菜单列表
     */
    DEVICE_MENU_LIST:'DEVICE_MENU_LIST',
    /**
     * 可能的原因可显示
     */
    REASON_MODAL_VISIBLE:'REASON_MODAL_VISIBLE',
    /**
     * 切换顶部菜单
     */
    HEADER_MENU_TAB:'HEADER_MENU_TAB',
    /**
     * 通话时间
     */
    SESSION_TIME:'SESSION_TIME',

}
export default EVENT_NAME
