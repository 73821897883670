import $event, { EVENT_NAME } from "@/utils/event-emitter";
/**
 * 防抖方法
 * @param fnName
 * @param time
 * @returns {function(): void}
 * @constructor
 */
export const onDebounce = (func, wait, immediate) => {
    let timeout

    return function(...args) {
        let context = this
        if (timeout) clearTimeout(timeout)

        if (immediate) {
            let callNow = !timeout
            timeout = setTimeout(function() {
                timeout = null
            }, wait)
            if (callNow) func.apply(context, args)
        } else {
            timeout = setTimeout(function() {
                func.apply(context, args)
            }, wait)
        }
    }
}

/**
 * 大小转化B KB MB GB的转化方法
 * @param limit
 * @returns {string}
 */
export const renderSize = (filesize) => {
    if(null==filesize||filesize==''){
        return "0 Bytes";
    }
    var unitArr = new Array("Bytes","KB","MB","GB","TB","PB","EB","ZB","YB");
    var index=0;
    var srcsize = parseFloat(filesize);
    index=Math.floor(Math.log(srcsize)/Math.log(1024));
    var size =srcsize/Math.pow(1024,index);
    size=size.toFixed(2);//保留的小数位数
    return size+unitArr[index];
}


/**
 *  验证手机号
 * @param phone
 * @returns {boolean}
 */
export const validatePhone = (phone) => {
    let phoneRe = /^1(3|4|5|6|7|8|9)\d{9}$/;
    return phoneRe.test(phone);
}

// /**
//  *  验证邮箱
//  * @param email
//  * @returns {boolean}
//  */
// export const validateEmail = (email) => {
//     let emailRe = /^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/;
//     return emailRe.test(email);
// }

export const isMobile = () => {
    let device = ''
    let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
    if(flag === null) {
        device = 'PC'
    }else {
        if(flag == 'iPhone,iPhone'){
            device = 'IOS'
        }
        if(flag == 'Android,Android'){
            device = 'ANDROID'
        }
        if(flag == 'iPad,iPad'){
            device = 'IOS'
        }
    }
    return device
}

/**
 * 将Base64 转成文件
 * @param dataurl
 * @param filename
 * @returns {File}
 */
export const dataURLtoFile = (dataurl, filename) => {
    let arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, {type:mime});
}

/**
 * 提取设备分辨率
 * @param obj
 * @returns {*}
 */
export const extractDevice = (obj) => {
    return obj.join().replace(/[^0-9]/ig,",").split(',')
}

//保存二维码
export const saveCode = (data,image) => {
    const fileName = data + '.png'
    let img = image

    let aLink = document.createElement('a')
    let blob = base64ToBlob(img)
    let evt = document.createEvent('HTMLEvents')
    evt.initEvent('click', true, true) // initEvent 不加后两个参数在FF下会报错  事件类型，是否冒泡，是否阻止浏览器的默认行为
    aLink.download = fileName
    aLink.href = URL.createObjectURL(blob)
    // aLink.dispatchEvent(evt);
    aLink.click()
}
//base64转blob
export const base64ToBlob = (code) => {
    let parts = code.split(';base64,')
    let contentType = parts[0].split(':')[1]
    let raw = window.atob(parts[1])
    let rawLength = raw.length
    let uInt8Array = new Uint8Array(rawLength)
    for (let i = 0; i < rawLength; ++i) {
        uInt8Array[i] = raw.charCodeAt(i)
    }
    return new Blob([uInt8Array], { type: contentType })
}

/**
 * 返回日期插件
 * @returns {string}
 */
export const dataUnit = () => {
    let newData = new Date();
    let year = newData.getFullYear(); //获取完整的年份(4位)
    let mouth = newData.getMonth()+1;
    let data = newData.getDate();
    mouth<10?mouth = '0'+mouth:mouth
    data<10?data = '0'+data:data
    return year+''+mouth+''+data
}

/**
 * 会话时长
 * @type {null}
 */
let interval = null
export const sessionTime = () =>{
    clearSessionTime()
    let second = 0
    let min = 0
    let hour = 0
    let hourValue;
    let minValue;
    let secondValue;
    interval = window.setInterval(() => {
        second = second+1;
            if(second >= 60){
                min++
                second = 0
            }
            secondValue = second < 10 ? '0' + second : second
            if(min >= 60){
                hour++
                min = 0
            }
           minValue = min < 10 ? '0' + min : min
           hourValue = hour < 10 ? '0' + hour : hour
           $event.$emit(EVENT_NAME.SESSION_TIME,hourValue + ' : ' + minValue + ' : ' + secondValue)
    },1000)
}

/**
 * 清除计时器
 */
export const clearSessionTime = () =>{
    window.clearInterval(interval)
}


/**
 * 处理下载接口返回的文件流数据
 * @param {*} res http请求返回数据
 */
export const downloadFile = (row,data,type) => {
    let blob
    if(type === 'excel'){
        blob = new Blob([data],{type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'})
    }else{
        blob = new Blob([data],{type: 'application/pdf'})
    }
    if ('download' in document.createElement('a')) { // 非IE下载
        const link = document.createElement('a')
        if(type === 'excel'){
            link.download = dataUnit()+'-'+row.flowName+'-'+row.recordCode+'.xlsx'
        }else{
            link.download = row.recordCode
        }
        link.style.display = 'none'
        link.href = URL.createObjectURL(blob)
        document.body.appendChild(link)
        link.click()
        URL.revokeObjectURL(link.href) // 释放URL 对象
        document.body.removeChild(link)
    } else { // IE10+下载
        if(type === 'excel'){
            navigator.msSaveBlob(blob, dataUnit()+'-'+row.flowName+'-'+row.recordCode+'.xlsx')
        }else{
            navigator.msSaveBlob(blob, row.recordCode)
        }
    }
}

/**
 * 下载图片
 * @param url
 * @param name
 */
export const saveImage = (url,name) => {
    let image = new Image()
    image.setAttribute('crossOrigin', 'anonymous')
    image.src = url
    image.onload = () => {
        let canvas = document.createElement('canvas')
        canvas.width = image.width
        canvas.height = image.height
        let ctx = canvas.getContext('2d')
        ctx.drawImage(image, 0, 0, image.width, image.height)
        canvas.toBlob((blob) => {
            let url = URL.createObjectURL(blob)
            let eleLink = document.createElement('a')
            eleLink.download = name
            eleLink.href = url
            eleLink.click()
            eleLink.remove()
            // 用完释放URL对象
            URL.revokeObjectURL(url)
        })
    }
}
